import Header from "../components/header";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import mainStore from "../../store/store";
import Summary from "../components/summary";
import { useEffect, useState } from "react";
import moment from "moment";
import Footer from "../components/footer";
import Stepper from "../components/stepper";
import { timeOptions } from "../../utils/constants";

const validationSchema = Yup.object().shape({
  pickUpLocation: Yup.string().required("Pick-up location is required"),
  pickUpDate: Yup.date()
    .required("Pick-up date is required")
    .typeError("Invalid date format"),
  pickUpTime: Yup.string().required("Pick-up time is required"),
  returnDate: Yup.date()
    .required("Return date is required")
    .typeError("Invalid date format"),
  returnTime: Yup.string().required("Return time is required"),
  driverAge: Yup.number()
    .min(18, "Driver must be at least 18 years old")
    .typeError("Driver age must be a number")
    .required("Driver age is required"),
  agreeToTerms: Yup.boolean()
    .oneOf([true], "You must agree to the terms and conditions")
    .required("You must agree to the terms"),
});

const Booking = () => {
  const navigation = useNavigate();
  const { userBooking, setUserBooking, visitedRoutes, setVisitedRoutes } =
    mainStore();

  const [initialValues, setInitialValues] = useState(userBooking.booking);

  useEffect(() => {
    const updatedRoutes = [...visitedRoutes];
    updatedRoutes.push("booking");
    setVisitedRoutes(updatedRoutes);
  }, []);

  useEffect(() => {
    setInitialValues(userBooking.booking);
  }, [userBooking]);

  const onSelectingPickUpDate = (e, setFieldValue) => {
    setFieldValue("pickUpDate", e.target.value);

    const pickUpDateTime = moment(
      `${e.target.value}T${moment().format("hh:mm")}`
    );
    const returnDateTime = pickUpDateTime.add(7, "days");
    setFieldValue("returnDate", returnDateTime.format("YYYY-MM-DD"));
    setFieldValue("returnTime", returnDateTime.format("HH:mm"));
  };

  const handleSubmit = async (values, validateForm) => {
    const formValidated = await validateForm();
    console.log("handleSubmit ~ formValidated:", formValidated);
    if (Object.keys(formValidated).length) {
      return;
    }

    let booking = { ...userBooking };
    booking.booking = values;
    setUserBooking(booking);

    navigation("/rental-form");
  };

  return (
    <>
      <Header />
      <section class="MainSection">
        <div class="container">
          <h1 class="Head_2">Book Your Car</h1>
        </div>
      </section>
      <section class="BookingSection">
        <div class="container">
          <Stepper />
          <div class="row">
            <div class="col-md-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, validateForm, setFieldValue }) => (
                  <Form class="BookingFrom" action="rental.html">
                    <div class="Form">
                      <div class="MainField">
                        <label for="PickupLocation" class="form-label">
                          Pickup Location
                        </label>
                        <div class="InputField">
                          <Field
                            name="pickUpLocation"
                            as="select"
                            className={`form-control ${
                              errors.pickUpLocation && touched.pickUpLocation
                                ? "error"
                                : ""
                            }`}
                          >
                            <option value="East Perth">East Perth</option>
                            <option value="Perth Airport">Perth Airport</option>
                          </Field>
                        </div>
                      </div>
                      <div class="MainField">
                        <label for="PickupDateTime" class="form-label">
                          Pickup Date & Time
                        </label>
                        <div class="Field">
                          <div class="InputField">
                            <Field
                              name="pickUpDate"
                              type="date"
                              className={`form-control ${
                                errors.pickUpDate && touched.pickUpDate
                                  ? "error"
                                  : ""
                              }`}
                              onChange={(e) =>
                                onSelectingPickUpDate(e, setFieldValue)
                              }
                            />
                          </div>
                          <div class="InputField">
                            <Field
                              name="pickUpTime"
                              as="select"
                              className={`form-control ${
                                errors.pickUpTime && touched.pickUpTime
                                  ? "error"
                                  : ""
                              }`}
                            >
                              {timeOptions.map((time) => (
                                <option value={time.value}>{time.label}</option>
                              ))}
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div class="MainField">
                        <label for="PickupDateTime" class="form-label">
                          Return Date & Time
                        </label>
                        <div class="Field">
                          <div class="InputField">
                            <Field
                              name="returnDate"
                              type="date"
                              placeholder="Return Date"
                              className={`form-control ${
                                errors.returnDate && touched.returnDate
                                  ? "error"
                                  : ""
                              }`}
                            />
                          </div>
                          <div class="InputField">
                            <Field
                              name="returnTime"
                              as="select"
                              placeholder="Pickup Time"
                              className={`form-control ${
                                errors.returnTime && touched.returnTime
                                  ? "error"
                                  : ""
                              }`}
                            >
                              {timeOptions.map((time) => (
                                <option value={time.value}>{time.label}</option>
                              ))}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div class="MainField">
                        <label for="DriverAge" class="form-label">
                          Driver's Age
                        </label>
                        <div class="InputField">
                          <Field
                            name="driverAge"
                            type="number"
                            className={`form-control ${
                              errors.driverAge && touched.driverAge
                                ? "error"
                                : ""
                            }`}
                          ></Field>
                          <ErrorMessage
                            name="driverAge"
                            component="div"
                            className="error-text"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="CheckBox">
                      <Field
                        name="agreeToTerms"
                        type="checkbox"
                        className={`form-check-input ${
                          errors.agreeToTerms && touched.agreeToTerms
                            ? "error"
                            : ""
                        }`}
                      ></Field>
                      <label class="form-check-label" for="agreeToTerms">
                        I agree to the Privacy Policy
                      </label>
                    </div>
                    <button
                      class="BtnFill"
                      type="submit"
                      onClick={() => handleSubmit(values, validateForm)}
                    >
                      Next
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div class="col-md-4">
              <Summary />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Booking;
