export const timeOptions = [
  { label: "12:00 AM", value: "12:00 AM" },
  { label: "12:30 AM", value: "12:30 AM" },
  { label: "01:00 AM", value: "1:00 AM" },
  { label: "01:30 AM", value: "1:30 AM" },
  { label: "02:00 AM", value: "2:00 AM" },
  { label: "02:30 AM", value: "2:30 AM" },
  { label: "03:00 AM", value: "3:00 AM" },
  { label: "03:30 AM", value: "3:30 AM" },
  { label: "04:00 AM", value: "4:00 AM" },
  { label: "04:30 AM", value: "4:30 AM" },
  { label: "05:00 AM", value: "5:00 AM" },
  { label: "05:30 AM", value: "5:30 AM" },
  { label: "06:00 AM", value: "6:00 AM" },
  { label: "06:30 AM", value: "6:30 AM" },
  { label: "07:00 AM", value: "7:00 AM" },
  { label: "07:30 AM", value: "7:30 AM" },
  { label: "08:00 AM", value: "8:00 AM" },
  { label: "08:30 AM", value: "8:30 AM" },
  { label: "09:00 AM", value: "9:00 AM" },
  { label: "09:30 AM", value: "9:30 AM" },
  { label: "10:00 AM", value: "10:00 AM" },
  { label: "10:30 AM", value: "10:30 AM" },
  { label: "11:00 AM", value: "11:00 AM" },
  { label: "11:30 AM", value: "11:30 AM" },
  { label: "12:00 PM", value: "12:00 PM" },
  { label: "12:30 PM", value: "12:30 PM" },
  { label: "01:00 PM", value: "1:00 PM" },
  { label: "01:30 PM", value: "1:30 PM" },
  { label: "02:00 PM", value: "2:00 PM" },
  { label: "02:30 PM", value: "2:30 PM" },
  { label: "03:00 PM", value: "3:00 PM" },
  { label: "03:30 PM", value: "3:30 PM" },
  { label: "04:00 PM", value: "4:00 PM" },
  { label: "04:30 PM", value: "4:30 PM" },
  { label: "05:00 PM", value: "5:00 PM" },
  { label: "05:30 PM", value: "5:30 PM" },
  { label: "06:00 PM", value: "6:00 PM" },
  { label: "06:30 PM", value: "6:30 PM" },
  { label: "07:00 PM", value: "7:00 PM" },
  { label: "07:30 PM", value: "7:30 PM" },
  { label: "08:00 PM", value: "8:00 PM" },
  { label: "08:30 PM", value: "8:30 PM" },
  { label: "09:00 PM", value: "9:00 PM" },
  { label: "09:30 PM", value: "9:30 PM" },
  { label: "10:00 PM", value: "10:00 PM" },
  { label: "10:30 PM", value: "10:30 PM" },
  { label: "11:00 PM", value: "11:00 PM" },
  { label: "11:30 PM", value: "11:30 PM" },
];
