import { useMemo } from "react";
import mainStore from "../../store/store";

const Summary = () => {
  const { userBooking } = mainStore();
  console.log("Summary ~ userBooking:", userBooking);

  const calculateExtra = useMemo(() => {
    return userBooking.extras.reduce((acc, extra) => {
      return acc + extra.price;
    }, 0);
  }, [userBooking]);

  return (
    <>
      <div class="Summary">
        <h2 class="Head_3">Rental Summary</h2>
        <p class="Pra_1">
          Prices may change depending on the length of the rental and the price
          of your rental car.
        </p>
        <div class="Details">
          <div class="Image">
            <img src="img/car_details.png" alt="Car" />
          </div>
          <div class="Name">
            <h3 class="Head_5">{userBooking.car.name}</h3>
            <div class="Icon">
              <div class="Features">
                <img src="img/icon/air_conditioning.svg" alt="Icon" />
                <img src="img/icon/gps_navigation.svg" alt="Icon" />
                <img src="img/icon/bluetooth.svg" alt="Icon" />
                <img src="img/icon/camera.svg" alt="Icon" />
                <img src="img/icon/cruise_control.svg" alt="Icon" />
              </div>
              <div class="Rating">
                <img src="img/icon/star.svg" alt="Star" />
                <img src="img/icon/star.svg" alt="Star" />
                <img src="img/icon/star.svg" alt="Star" />
                <img src="img/icon/star.svg" alt="Star" />
                <img src="img/icon/star_unfill.svg" alt="Star" />
              </div>
            </div>
          </div>
        </div>
        <div class="Price">
          <p>
            Subtotal <span>${userBooking.rate.rate}</span>
          </p>
          <p>
            Extra <span>${calculateExtra}</span>
          </p>
          {/* <p>
            Tax <span>$0</span>
          </p> */}
        </div>
        <div class="TotalPrice">
          <div class="Head">
            <h4>Total Rental Price</h4>
            <p class="Pra_1">Price & includes rental discount</p>
          </div>
          <h5 class="Head_3">${userBooking.rate.rate + calculateExtra}</h5>
        </div>
      </div>
    </>
  );
};

export default Summary;
