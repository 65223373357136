import mainStore from "../../store/store";
import { useNavigate } from "react-router-dom";

const Stepper = () => {
  const navigate = useNavigate();
  const { userBooking, setSelectedRoute, visitedRoutes } = mainStore();

  const routeTo = (routeTo) => {
    if (
      (routeTo === "/cars" && visitedRoutes.includes("car")) ||
      (routeTo === "/car-details" && visitedRoutes.includes("car-details")) ||
      (routeTo === "/booking" && visitedRoutes.includes("booking")) ||
      (routeTo === "/rental-form" && visitedRoutes.includes("rental-form")) ||
      (routeTo === "/extras" && visitedRoutes.includes("extras"))
    ) {
      setSelectedRoute(routeTo);
      navigate(routeTo);
    }
  };

  return (
    <>
      <ul class="Stepper cursor-pointer">
        <li
          className={`${visitedRoutes.includes("car") ? "Fill" : "Active"}`}
          onClick={() => routeTo("/cars")}
        >
          <span>
            <svg width="30" height="30" viewBox="0 0 30 30">
              <path d="M7.5 23.75V25C7.5 25.3542 7.38021 25.651 7.14062 25.8906C6.90104 26.1302 6.60417 26.25 6.25 26.25H5C4.64583 26.25 4.34896 26.1302 4.10938 25.8906C3.86979 25.651 3.75 25.3542 3.75 25V15L6.375 7.5C6.5 7.125 6.72396 6.82292 7.04688 6.59375C7.36979 6.36458 7.72917 6.25 8.125 6.25H21.875C22.2708 6.25 22.6302 6.36458 22.9531 6.59375C23.276 6.82292 23.5 7.125 23.625 7.5L26.25 15V25C26.25 25.3542 26.1302 25.651 25.8906 25.8906C25.651 26.1302 25.3542 26.25 25 26.25H23.75C23.3958 26.25 23.099 26.1302 22.8594 25.8906C22.6198 25.651 22.5 25.3542 22.5 25V23.75H7.5ZM7.25 12.5H22.75L21.4375 8.75H8.5625L7.25 12.5ZM9.375 20C9.89583 20 10.3385 19.8177 10.7031 19.4531C11.0677 19.0885 11.25 18.6458 11.25 18.125C11.25 17.6042 11.0677 17.1615 10.7031 16.7969C10.3385 16.4323 9.89583 16.25 9.375 16.25C8.85417 16.25 8.41146 16.4323 8.04688 16.7969C7.68229 17.1615 7.5 17.6042 7.5 18.125C7.5 18.6458 7.68229 19.0885 8.04688 19.4531C8.41146 19.8177 8.85417 20 9.375 20ZM20.625 20C21.1458 20 21.5885 19.8177 21.9531 19.4531C22.3177 19.0885 22.5 18.6458 22.5 18.125C22.5 17.6042 22.3177 17.1615 21.9531 16.7969C21.5885 16.4323 21.1458 16.25 20.625 16.25C20.1042 16.25 19.6615 16.4323 19.2969 16.7969C18.9323 17.1615 18.75 17.6042 18.75 18.125C18.75 18.6458 18.9323 19.0885 19.2969 19.4531C19.6615 19.8177 20.1042 20 20.625 20Z" />
            </svg>
          </span>
          <h6>Car</h6>
        </li>
        <li
          className={`${
            visitedRoutes.includes("car-details") ? "Fill" : "Active"
          }`}
          onClick={() => routeTo("/car-details")}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <path d="M22.5 16.25H7.5C6.675 16.25 6 16.925 6 17.75V21.25C6 22.075 6.675 22.75 7.5 22.75H22.5C23.325 22.75 24 22.075 24 21.25V17.75C24 16.925 23.325 16.25 22.5 16.25ZM7.5 17.75H22.5V21.25H7.5V17.75ZM18.5 11.25H11.5C11.075 11.25 10.75 11.575 10.75 12C10.75 12.425 11.075 12.75 11.5 12.75H18.5C18.925 12.75 19.25 12.425 19.25 12C19.25 11.575 18.925 11.25 18.5 11.25ZM15.5 6.25C15.5 6.675 15.175 7 14.75 7C14.325 7 14 6.675 14 6.25C14 5.825 14.325 5.5 14.75 5.5C15.175 5.5 15.5 5.825 15.5 6.25ZM9.5 6.25C9.5 6.675 9.175 7 8.75 7C8.325 7 8 6.675 8 6.25C8 5.825 8.325 5.5 8.75 5.5C9.175 5.5 9.5 5.825 9.5 6.25ZM20.5 6.25C20.5 6.675 20.175 7 19.75 7C19.325 7 19 6.675 19 6.25C19 5.825 19.325 5.5 19.75 5.5C20.175 5.5 20.5 5.825 20.5 6.25Z" />
            </svg>
          </span>
          <h6>Car Details</h6>
        </li>
        <li
          className={`${visitedRoutes.includes("booking") ? "Fill" : "Active"}`}
          onClick={() => routeTo("/booking")}
        >
          <span>
            <svg width="30" height="30" viewBox="0 0 30 30">
              <path d="M6.25 27.5C5.5625 27.5 4.97396 27.2552 4.48438 26.7656C3.99479 26.276 3.75 25.6875 3.75 25V7.5C3.75 6.8125 3.99479 6.22396 4.48438 5.73437C4.97396 5.24479 5.5625 5 6.25 5H7.5V2.5H10V5H20V2.5H22.5V5H23.75C24.4375 5 25.026 5.24479 25.5156 5.73437C26.0052 6.22396 26.25 6.8125 26.25 7.5V25C26.25 25.6875 26.0052 26.276 25.5156 26.7656C25.026 27.2552 24.4375 27.5 23.75 27.5H6.25ZM6.25 25H23.75V12.5H6.25V25Z" />
            </svg>
          </span>
          <h6>Booking</h6>
        </li>
        <li
          className={`${
            visitedRoutes.includes("rental-form") ? "Fill" : "Active"
          }`}
          onClick={() => routeTo("/rental-form")}
        >
          <span>
            <svg width="30" height="30" viewBox="0 0 30 30">
              <path d="M7.5 23.75V25C7.5 25.3542 7.38021 25.651 7.14062 25.8906C6.90104 26.1302 6.60417 26.25 6.25 26.25H5C4.64583 26.25 4.34896 26.1302 4.10938 25.8906C3.86979 25.651 3.75 25.3542 3.75 25V15L6.375 7.5C6.5 7.125 6.72396 6.82292 7.04688 6.59375C7.36979 6.36458 7.72917 6.25 8.125 6.25H21.875C22.2708 6.25 22.6302 6.36458 22.9531 6.59375C23.276 6.82292 23.5 7.125 23.625 7.5L26.25 15V25C26.25 25.3542 26.1302 25.651 25.8906 25.8906C25.651 26.1302 25.3542 26.25 25 26.25H23.75C23.3958 26.25 23.099 26.1302 22.8594 25.8906C22.6198 25.651 22.5 25.3542 22.5 25V23.75H7.5ZM7.25 12.5H22.75L21.4375 8.75H8.5625L7.25 12.5ZM9.375 20C9.89583 20 10.3385 19.8177 10.7031 19.4531C11.0677 19.0885 11.25 18.6458 11.25 18.125C11.25 17.6042 11.0677 17.1615 10.7031 16.7969C10.3385 16.4323 9.89583 16.25 9.375 16.25C8.85417 16.25 8.41146 16.4323 8.04688 16.7969C7.68229 17.1615 7.5 17.6042 7.5 18.125C7.5 18.6458 7.68229 19.0885 8.04688 19.4531C8.41146 19.8177 8.85417 20 9.375 20ZM20.625 20C21.1458 20 21.5885 19.8177 21.9531 19.4531C22.3177 19.0885 22.5 18.6458 22.5 18.125C22.5 17.6042 22.3177 17.1615 21.9531 16.7969C21.5885 16.4323 21.1458 16.25 20.625 16.25C20.1042 16.25 19.6615 16.4323 19.2969 16.7969C18.9323 17.1615 18.75 17.6042 18.75 18.125C18.75 18.6458 18.9323 19.0885 19.2969 19.4531C19.6615 19.8177 20.1042 20 20.625 20Z" />
            </svg>
          </span>
          <h6>Rental</h6>
        </li>
        <li
          className={`${visitedRoutes.includes("extras") ? "Fill" : "Active"}`}
          onClick={() => routeTo("/extras")}
        >
          <span>
            <svg width="30" height="30" viewBox="0 0 30 30">
              <path d="M11 26.25H6.25C5.5625 26.25 4.97396 26.0052 4.48438 25.5156C3.99479 25.026 3.75 24.4375 3.75 23.75V19C4.75 19 5.625 18.6823 6.375 18.0469C7.125 17.4115 7.5 16.6042 7.5 15.625C7.5 14.6458 7.125 13.8385 6.375 13.2031C5.625 12.5677 4.75 12.25 3.75 12.25V7.5C3.75 6.8125 3.99479 6.22396 4.48438 5.73438C4.97396 5.24479 5.5625 5 6.25 5H11.25C11.25 4.125 11.5521 3.38542 12.1562 2.78125C12.7604 2.17708 13.5 1.875 14.375 1.875C15.25 1.875 15.9896 2.17708 16.5938 2.78125C17.1979 3.38542 17.5 4.125 17.5 5H22.5C23.1875 5 23.776 5.24479 24.2656 5.73438C24.7552 6.22396 25 6.8125 25 7.5V12.5C25.875 12.5 26.6146 12.8021 27.2188 13.4062C27.8229 14.0104 28.125 14.75 28.125 15.625C28.125 16.5 27.8229 17.2396 27.2188 17.8438C26.6146 18.4479 25.875 18.75 25 18.75V23.75C25 24.4375 24.7552 25.026 24.2656 25.5156C23.776 26.0052 23.1875 26.25 22.5 26.25H17.75C17.75 25.2083 17.4219 24.3229 16.7656 23.5938C16.1094 22.8646 15.3125 22.5 14.375 22.5C13.4375 22.5 12.6406 22.8646 11.9844 23.5938C11.3281 24.3229 11 25.2083 11 26.25Z" />
            </svg>
          </span>
          <h6>Extras</h6>
        </li>
      </ul>
    </>
  );
};

export default Stepper;
