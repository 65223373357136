import Header from "../components/header";
import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import mainStore from "../../store/store";
import Summary from "../components/summary";
import Footer from "../components/footer";
import Stepper from "../components/stepper";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  contact: Yup.string().required("Contact is required"),
  licenseNumber: Yup.string()
    .min(6, "License number must be at least 6 characters")
    .required("License number is required"),
  address: Yup.string().required("Address is required"),
  comments: Yup.string(),
});

const RentalForm = () => {
  const navigation = useNavigate();
  const { userBooking, setUserBooking, visitedRoutes, setVisitedRoutes } =
    mainStore();

  const initialValues = userBooking.customer;

  useEffect(() => {
    const updatedRoutes = [...visitedRoutes];
    updatedRoutes.push("rental-form");
    setVisitedRoutes(updatedRoutes);
  }, []);

  const handleSubmit = async (values, validateForm) => {
    const formValidated = await validateForm();
    if (Object.keys(formValidated).length) {
      return;
    }
    let booking = { ...userBooking };
    booking.customer = values;
    setUserBooking(booking);

    navigation("/extras");
  };

  return (
    <>
      <Header />
      <section class="MainSection">
        <div class="container">
          <h1 class="Head_2">Rental Form</h1>
        </div>
      </section>
      <section class="BookingSection">
        <div class="container">
          <Stepper />
          <div class="row">
            <div class="col-md-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, errors, touched, values, validateForm }) => (
                  <Form class="BookingFrom" action="extras.html">
                    <div class="Form">
                      <div class="MainField">
                        <label for="FirstName" class="form-label">
                          First Name
                        </label>
                        <div class="InputField">
                          <Field
                            name="firstName"
                            placeholder="Enter First Name"
                            className={`form-control ${
                              errors.firstName && touched.firstName
                                ? "error"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                      <div class="MainField">
                        <label for="LastName" class="form-label">
                          Last Name
                        </label>
                        <div class="InputField">
                          <Field
                            name="lastName"
                            id="LastName"
                            placeholder="Enter Last Name"
                            className={`form-control ${
                              errors.lastName && touched.lastName ? "error" : ""
                            }`}
                          />
                        </div>
                      </div>
                      <div class="MainField">
                        <label for="Email" class="form-label">
                          Email
                        </label>
                        <div class="InputField">
                          <Field
                            name="email"
                            type="email"
                            id="Email"
                            placeholder="Enter Email"
                            className={`form-control ${
                              errors.email && touched.email ? "error" : ""
                            }`}
                          />
                        </div>
                      </div>
                      <div class="MainField">
                        <label for="ContactNumber" class="form-label">
                          Contact Number
                        </label>
                        <div class="InputField">
                          <Field
                            name="contact"
                            type="tel"
                            id="ContactNumber"
                            placeholder="Enter Contact Number"
                            className={`form-control ${
                              errors.contact && touched.contact ? "error" : ""
                            }`}
                          />
                        </div>
                      </div>
                      <div class="MainField">
                        <label for="LicenseNumber" class="form-label">
                          License Number
                        </label>
                        <div class="InputField">
                          <Field
                            name="licenseNumber"
                            id="LicenseNumber"
                            type="number"
                            placeholder="Enter License Number"
                            className={`form-control ${
                              errors.licenseNumber && touched.licenseNumber
                                ? "error"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                      <div class="MainField">
                        <label for="Address" class="form-label">
                          Address
                        </label>
                        <div class="InputField">
                          <Field
                            name="address"
                            class="form-control"
                            id="Address"
                            placeholder="Enter Address"
                            className={`form-control ${
                              errors.address && touched.address ? "error" : ""
                            }`}
                          />
                        </div>
                      </div>
                      <div class="MainField">
                        <label for="Comments" class="form-label">
                          Comments
                        </label>
                        <div class="InputField">
                          <Field
                            name="comments"
                            as="textarea"
                            class="form-control"
                            id="Comments"
                            placeholder="Enter Comments"
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      class="BtnFill"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={() => handleSubmit(values, validateForm)}
                    >
                      Next
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div class="col-md-4">
              <Summary />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default RentalForm;
