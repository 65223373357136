import { useEffect } from "react";
import Header from "../components/header";
import { useNavigate } from "react-router-dom";
import mainStore from "../../store/store";
import Footer from "../components/footer";

const ThankYou = () => {
  const naviagate = useNavigate();
  const { userBooking } = mainStore();

  useEffect(() => {
    console.log("userBooking", userBooking);
  }, [userBooking]);

  return (
    <>
      <Header />
      <section class="MainSection">
        <div class="container">
          <h1 class="Head_2">Thank you</h1>
        </div>
      </section>
      <section class="ThankSection">
        <div class="container">
          <img src="img/thankyou.png" alt="Thank You" />
          <h2 class="Head_2">Successfully Submitted</h2>
          <p class="Pra_2">The booking has been successfully submitted.</p>
          <a class="BtnFill" onClick={() => naviagate("/")}>
            Go Back To Home
          </a>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ThankYou;
