import axios from "axios";

const sendBookingEmail = async (data) => {
  const apiKey =
    "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";
  console.log("sendBookingEmail ~ apiKey:", apiKey);
  const endpoint = "https://api.brevo.com/v3/smtp/email";
  const { car, rate, booking, customer, extras } = data;

  const extrasList = extras
    .map((extra) => `<li>${extra.title}: $${extra.price}</li>`)
    .join("");

  const emailData = {
    sender: { name: "Tak8", email: "bookings@tak8.com.au" }, // Replace with your domain email
    to: [
      {
        email: customer.email,
        name: `${customer.firstName} ${customer.lastName}`,
      },
    ],
    subject: `Your Booking Confirmation for ${car.name}`,
    htmlContent: `
      <h1>Booking Confirmation</h1>
      <p>Dear ${customer.firstName} ${customer.lastName},</p>
      <p>Thank you for booking with us! Here are your booking details:</p>
      <h2>Car Details</h2>
      <ul>
        <li><strong>Name:</strong> ${car.name}</li>
        <li><strong>Brand:</strong> ${car.brand}</li>
        <li><strong>Model:</strong> ${car.model}</li>
        <li><strong>KM/H:</strong> ${car.kmh}</li>
        <li><strong>Description:</strong> ${car.description}</li>
        <li><strong>Transmission:</strong> ${
          car.isAutomatic ? "Automatic" : "Manual"
        }</li>
        <li><strong>Default Rate Per Day:</strong> $${
          car.defaultRatePerDay
        }</li>
      </ul>
      <h2>Booking Details</h2>
      <ul>
        <li><strong>Pick-Up Location:</strong> ${booking.pickUpLocation}</li>
        <li><strong>Pick-Up Date:</strong> ${booking.pickUpDate}</li>
        <li><strong>Pick-Up Time:</strong> ${booking.pickUpTime}</li>
        <li><strong>Return Location:</strong> ${booking.returnLocation}</li>
        <li><strong>Return Date:</strong> ${booking.returnDate}</li>
        <li><strong>Return Time:</strong> ${booking.returnTime}</li>
        <li><strong>Driver Age:</strong> ${booking.driverAge}</li>
        <li><strong>Terms Agreed:</strong> ${
          booking.agreeToTerms ? "Yes" : "No"
        }</li>
      </ul>
      <h2>Rate</h2>
      <ul>
        <li><strong>Title:</strong> ${rate.title}</li>
        <li><strong>Rate:</strong> $${rate.rate}</li>
      </ul>
      <h2>Extras</h2>
      <ul>
        ${extrasList || "<li>No extras added</li>"}
      </ul>
      <h2>Customer Details</h2>
      <ul>
        <li><strong>Full Name:</strong> ${customer.firstName} ${
      customer.lastName
    }</li>
        <li><strong>Email:</strong> ${customer.email}</li>
        <li><strong>Contact:</strong> ${customer.contact}</li>
        <li><strong>License Number:</strong> ${customer.licenseNumber}</li>
        <li><strong>Address:</strong> ${customer.address}</li>
        <li><strong>Comments:</strong> ${customer.comments || "N/A"}</li>
      </ul>
      <p>We look forward to serving you. If you have any questions, feel free to contact us.</p>
      <p>Best regards,<br>Felo Team</p>
    `,
  };

  try {
    const response = await axios.post(endpoint, emailData, {
      headers: {
        "api-key": apiKey,
        "Content-Type": "application/json",
      },
    });
    console.log("Email sent successfully:", response.data);
  } catch (error) {
    console.error(
      "Error sending email:",
      error.response ? error.response.data : error.message
    );
  }
};

export default sendBookingEmail;
