import Header from "../components/header";
import mainStore from "../../store/store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import Stepper from "../components/stepper";

const Cars = () => {
  const navigate = useNavigate();
  const {
    getAllCars,
    userBooking,
    setUserBooking,
    visitedRoutes,
    setVisitedRoutes,
  } = mainStore();
  const [allCarsList, setAllCarsList] = useState([]);

  useEffect(() => {
    initializeCars();
  }, []);

  const initializeCars = async () => {
    const updatedRoutes = [...visitedRoutes];
    updatedRoutes.push("car");
    setVisitedRoutes(updatedRoutes);

    const res = await getAllCars();
    if (res) {
      setAllCarsList(res);
    }
    console.log("initializeCars ~ res:", res);
  };

  const onSelectingCar = (car) => {
    let booking = { ...userBooking };
    booking.car = car;
    setUserBooking(booking);

    navigate(`/car-details?id=${car._id}`);
  };

  const getCarImg = (car) => {
    if (car.name === "Swift") {
      return "img/car_1.png";
    } else if (car.name === "Nissan Altima") {
      return "img/car_2.png";
    } else if (car.name === "Lexus RX") {
      return "img/car_3.png";
    } else if (car.name === "Toyota Corolla") {
      return "img/car_4.png";
    } else if (car.name === "GMC Sierra") {
      return "img/car_5.png";
    } else if (car.name === "Tesla Model 3") {
      return "img/car_6.png";
    }
  };

  return (
    <>
      <Header />
      <section class="MainSection">
        <div class="container">
          <h1 class="Head_2">Cars</h1>
        </div>
      </section>
      <section>
        <div class="container">
          <Stepper />
          <div class="row">
            {allCarsList.map((car) => (
              <div class="col-md-4">
                <div class="CarBox">
                  <div class="Image">
                    <img src={getCarImg(car)} alt="car" />
                    {car.isAutomatic ? (
                      <span class="Tag Red">Automatic</span>
                    ) : (
                      <span class="Tag Yellow">Manual</span>
                    )}
                  </div>
                  <div class="Content">
                    <div class="Heading">
                      <h4 class="Head_3">{car.name}</h4>
                      <div class="Features">
                        <img src="img/icon/air_conditioning.svg" alt="Icon" />
                        <img src="img/icon/gps_navigation.svg" alt="Icon" />
                        <img src="img/icon/bluetooth.svg" alt="Icon" />
                        <img src="img/icon/camera.svg" alt="Icon" />
                        <img src="img/icon/cruise_control.svg" alt="Icon" />
                      </div>
                    </div>
                    <div class="Price">
                      <h5>
                        ${car.defaultRatePerDay}
                        <span>/Day</span>
                      </h5>
                      <p>
                        <img src="img/icon/car.svg" alt="Car" />
                        {car.brand} {car.model}
                      </p>
                    </div>
                    <a
                      onClick={() => {
                        onSelectingCar(car);
                      }}
                      class="BtnBorder cursor-pointer"
                    >
                      Booking Details
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Cars;
