import Header from "../components/header";
import mainStore from "../../store/store";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { timeOptions } from "../../utils/constants";

const Home = () => {
  const navigate = useNavigate();
  const { userBooking, setUserBooking } = mainStore();
  const [findRide, setFindRide] = useState({
    pickUpLocation: "East Perth",
    pickUpDate: moment().format("YYYY-MM-DD"),
    pickUpTime: moment().format("HH:mm"),
    returnLocation: "",
    returnDate: moment().add(7, "days").format("YYYY-MM-DD"),
    returnTime: moment().format("HH:mm"),
    driverAge: "",
    agreeToTerms: true,
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "pickUpDate") {
      onSelectingPickUpDate(e);
    } else {
      setFindRide({ ...findRide, [name]: value });
    }
  };
  const onSelectingPickUpDate = (e) => {
    const pickUpDateTime = moment(
      `${e.target.value}T${moment().format("hh:mm")}`
    );
    const returnDateTime = pickUpDateTime.add(7, "days");
    setFindRide({
      ...findRide,
      pickUpDate: e.target.value,
      returnDate: moment(returnDateTime).format("YYYY-MM-DD"),
    });
  };

  const onFindCar = () => {
    const booking = { ...userBooking };
    booking.booking = { ...booking.booking, ...findRide };
    setUserBooking(booking);
    navigate("/cars");
  };

  return (
    <>
      <Header />
      <section class="SearchSection">
        <div class="container">
          <form class="SearchFrom">
            <div class="Field">
              <label for="PickupLocation" class="form-label">
                Pickup
              </label>
              <div class="InputField">
                <img src="img/icon/location.svg" alt="Location" />
                <select
                  class="form-select"
                  for="PickupLocation"
                  aria-label="Default select example"
                  name="pickUpLocation"
                  onChange={onInputChange}
                >
                  <option selected>Select Location</option>
                  <option value="East Perth">East Perth</option>
                  <option value="Perth Airport">Perth Airport</option>
                </select>
              </div>
            </div>
            <span class="BorderLine"></span>

            <div class="Field">
              <label for="PickupTime" class="form-label">
                Pickup Date & Time
              </label>
              <div class="main-search">
                <div class="InputField">
                  <input
                    type="date"
                    class="form-control"
                    id="PickupTime"
                    placeholder="Enter Pickup Date"
                    name="pickUpDate"
                    value={findRide.pickUpDate}
                    onChange={onInputChange}
                  />
                </div>
                <div class="InputField">
                  <select
                    class="form-control"
                    id="PickupTime"
                    placeholder="Enter Pickup Time"
                    name="pickUpTime"
                    value={findRide.pickUpTime}
                    onChange={onInputChange}
                  >
                    {timeOptions.map((time) => (
                      <option value={time.value}>{time.label}</option>
                    ))}
                  </select>
                  <img src="img/icon/clock.svg" alt="Location" />
                </div>
              </div>
            </div>
            <span class="BorderLine"></span>

            {/* <div class="Field">
              <label for="PickupTime" class="form-label">
                Pickup Time
              </label>
            </div>
            <span class="BorderLine"></span> */}

            <div class="Field">
              <label for="DropOffTime" class="form-label">
                Drop Off Date & Time
              </label>
              <div class="main-search">
                <div class="InputField">
                  <input
                    type="date"
                    class="form-control"
                    id="DropOffDate"
                    placeholder="Enter Pickup Date"
                    name="returnDate"
                    value={findRide.returnDate}
                    onChange={onInputChange}
                  />
                </div>
                <div class="InputField">
                  <img src="img/icon/clock.svg" alt="Location" />
                  <select
                    class="form-control"
                    id="DropOffTime"
                    placeholder="Enter Return Time"
                    name="returnTime"
                    value={findRide.returnTime}
                    onChange={onInputChange}
                  >
                    {timeOptions.map((time) => (
                      <option value={time.value}>{time.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <span class="BorderLine"></span>

            {/* <div class="Field">
              <label for="DriverAge" class="form-label">
                Driver Age
              </label>
              <div class="InputField">
                <img src="img/icon/location.svg" alt="Location" />
                <input
                  type="number"
                  class="form-control"
                  for="DriverAge"
                  placeholder="Enter Age"
                  name="driverAge"
                  onChange={onInputChange}
                />
              </div>
            </div> */}
            <button class="BtnFill" type="button" onClick={() => onFindCar()}>
              Find Ride
            </button>
          </form>
        </div>
      </section>

      <section class="HomeSection">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-5">
              <h1>
                <span>Drive Your Journey </span>
                <br />
                Leave the Rest to Us!
              </h1>
              <p class="Pra_1">
                We have many types of cars that are ready for you to travel
                anywhere and anytime.
              </p>
              <div class="Button">
                <a href="#" class="BtnFill">
                  Get In Touch
                </a>
                <a href="#" class="BtnBorder">
                  Our Car
                </a>
              </div>
            </div>
            <div class="col-md-7">
              <img src="img/home_car.png" alt="Car" />
              <img src="img/home_car_mob.png" alt="Car" />=
            </div>
          </div>
        </div>
      </section>

      {/* <section class="LogoSection">
        <div class="container">
          <img src="img/logo_1.png" alt="Logo" />
          <img src="img/logo_2.png" alt="Logo" />
          <img src="img/logo_3.png" alt="Logo" />
          <img src="img/logo_4.png" alt="Logo" />
          <img src="img/logo_5.png" alt="Logo" />
          <img src="img/logo_6.png" alt="Logo" />
          <img src="img/logo_7.png" alt="Logo" />
          <img src="img/logo_8.png" alt="Logo" />
        </div>
      </section> */}

      {/* <section class="CarSection">
        <div class="container">
          <div class="CustomHeading">
            <h2 class="Head_1">POPULAR CAR</h2>
            <h3 class="Head_2">Choose Your Suitable Car</h3>
            <p class="Pra_1">
              We present popular cars that are rented by customers to maximize
              your comfort on long trips.
            </p>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-md-4">
              <div class="CarBox">
                <div class="Image">
                  <img src="img/car_1.png" alt="car" />
                  <span class="Tag Red">Automatic</span>
                </div>
                <div class="Content">
                  <div class="Heading">
                    <h4 class="Head_3">Swift</h4>
                    <div class="Features">
                      <img src="img/icon/air_conditioning.svg" alt="Icon" />
                      <img src="img/icon/gps_navigation.svg" alt="Icon" />
                      <img src="img/icon/bluetooth.svg" alt="Icon" />
                      <img src="img/icon/camera.svg" alt="Icon" />
                      <img src="img/icon/cruise_control.svg" alt="Icon" />
                    </div>
                  </div>
                  <div class="Price">
                    <h5>
                      $85<span>/Day</span>
                    </h5>
                    <p>
                      <img src="img/icon/car.svg" alt="Car" />
                      Suzuki 2024
                    </p>
                  </div>
                  <a href="#" class="BtnBorder">
                    Booking Details
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="CarBox">
                <div class="Image">
                  <img src="img/car_2.png" alt="car" />
                  <span class="Tag Yellow">Manual</span>
                </div>
                <div class="Content">
                  <div class="Heading">
                    <h4 class="Head_3">Nissan Altima</h4>
                    <div class="Features">
                      <img src="img/icon/air_conditioning.svg" alt="Icon" />
                      <img src="img/icon/gps_navigation.svg" alt="Icon" />
                      <img src="img/icon/bluetooth.svg" alt="Icon" />
                      <img src="img/icon/camera.svg" alt="Icon" />
                      <img src="img/icon/cruise_control.svg" alt="Icon" />
                    </div>
                  </div>
                  <div class="Price">
                    <h5>
                      $120<span>/Day</span>
                    </h5>
                    <p>
                      <img src="img/icon/car.svg" alt="Car" />
                      Nissan 2024
                    </p>
                  </div>
                  <a href="#" class="BtnBorder">
                    Booking Details
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="CarBox">
                <div class="Image">
                  <img src="img/car_3.png" alt="car" />
                  <span class="Tag Red">Automatic</span>
                </div>
                <div class="Content">
                  <div class="Heading">
                    <h4 class="Head_3">Lexus</h4>
                    <div class="Features">
                      <img src="img/icon/air_conditioning.svg" alt="Icon" />
                      <img src="img/icon/gps_navigation.svg" alt="Icon" />
                      <img src="img/icon/bluetooth.svg" alt="Icon" />
                      <img src="img/icon/camera.svg" alt="Icon" />
                      <img src="img/icon/cruise_control.svg" alt="Icon" />
                    </div>
                  </div>
                  <div class="Price">
                    <h5>
                      $150<span>/Day</span>
                    </h5>
                    <p>
                      <img src="img/icon/car.svg" alt="Car" />
                      Toyota 2024
                    </p>
                  </div>
                  <a href="#" class="BtnBorder">
                    Booking Details
                  </a>
                </div>
              </div>
            </div>
            <a href="#" class="Button BtnFill">
              See All
            </a>
          </div>
        </div>
      </section> */}

      <section class="ServicesSection">
        <div class="container">
          <div class="CustomHeading">
            <h2 class="Head_1">SERVICES</h2>
            <h3 class="Head_2">Our Services</h3>
            <p class="Pra_1">
              Our service is not only renting a car, but we also provide a
              private chauffeur service that can guide you <br />
              on your trip and also longtrip packages to support your travel
              needs.
            </p>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="ServiceBox">
                <img src="img/service_1.png" class="BoxImg" alt="Service" />
                <div class="Content">
                  <div class="Details">
                    <img src="img/icon/airport.svg" alt="Icon" />
                    <h4 class="Head_3">Perth Airport Service</h4>
                    <p class="Pra_1">
                      At TAK8, we're committed to providing unbeatable deals on
                      airport car rentals in Perth. With transparent pricing and
                      no hidden fees, you’ll always get exceptional value for
                      your money. Choose from our diverse range of
                      budget-friendly options, backed by top-notch..
                    </p>
                    <a href="#" class="BtnFill">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="ServiceBox">
                <img src="img/service_2.png" class="BoxImg" alt="Service" />
                <div class="Content">
                  <div class="Details">
                    <img src="img/icon/vehical.svg" alt="Icon" />
                    <h4 class="Head_3">Vehicle Rental Services</h4>
                    <p class="Pra_1">
                      Professional vehicle rental services, such as those
                      offered by TAK8, provide numerous benefits, making them
                      perfect for both personal and business requirements.
                      Emphasizing quality, reliability, and customer
                      satisfaction, TAK8 guarantees the right vehicle to suit
                      your...
                    </p>
                    <a href="#" class="BtnFill">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="ServiceBox">
                <img src="img/service_1.png" class="BoxImg" alt="Service" />
                <div class="Content">
                  <div class="Details">
                    <img src="img/icon/seats.svg" alt="Icon" />
                    <h4 class="Head_3">Car Rental With Seats</h4>
                    <p class="Pra_1">
                      What matters most is making the most of your trip. We
                      prioritize safety and comfort, which is why every vehicle
                      in our fleet comes equipped with premium, fully adjustable
                      car seats. Whether you’re booking a 7-seater for a family
                      getaway or a dependable car for business travel, we ...
                    </p>
                    <a href="#" class="BtnFill">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="AdvantagesSection">
        <div class="container">
          <div class="CustomHeading">
            <h2 class="Head_1">ADVANTAGES</h2>
            <h3 class="Head_2">Why Choose Us ?</h3>
            <p class="Pra_1">
              We present many guarantees and advantages when you rent a car with
              us for your trip. Here are some
              <br />
              of the advantages that you will get
            </p>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="Box">
                <img src="img/icon/rent.svg" class="BoxImg" alt="Icon" />
                <div class="Content">
                  <h4 class="Head_5">Easy Rent</h4>
                  <p class="Pra_1">
                    Rent a car at our rental with an easy and fast process
                    without disturbing your productivity
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="Box">
                <img src="img/icon/premium.svg" class="BoxImg" alt="Icon" />
                <div class="Content">
                  <h4 class="Head_5">Premium Quality</h4>
                  <p class="Pra_1">
                    Our cars are always maintained engine health and cleanliness
                    to provide a more comfortable driving experience
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="Box">
                <img src="img/icon/agent.svg" class="BoxImg" alt="Icon" />
                <div class="Content">
                  <h4 class="Head_5">Professional Agent</h4>
                  <p class="Pra_1">
                    You can ask your travel companion to escort and guide your
                    journey.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="Box">
                <img src="img/icon/safety.svg" class="BoxImg" alt="Icon" />
                <div class="Content">
                  <h4 class="Head_5">Car Safety</h4>
                  <p class="Pra_1">
                    We guarantee the safety of the engine on the car always
                    running well with regular checks on the car engine.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="Box">
                <img src="img/icon/refund.svg" class="BoxImg" alt="Icon" />
                <div class="Content">
                  <h4 class="Head_5">Refund</h4>
                  <p class="Pra_1">
                    Our service guarantee provides a money back opportunity if
                    the car does not match the information provided.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="Box">
                <img src="img/icon/live.svg" class="BoxImg" alt="Icon" />
                <div class="Content">
                  <h4 class="Head_5">Live Monitoring</h4>
                  <p class="Pra_1">
                    Our service provides direct customer monitoring to monitor
                    trips in terms of safety and comfort.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="TestimonialsSection">
        <div class="container">
          <div class="CustomHeading">
            <h2 class="Head_1">Testimonials</h2>
            <h3 class="Head_2">What They Say ?</h3>
            <p class="Pra_1">
              Here are some comments from our customers, be one of them
            </p>
          </div>

          <div class="row justify-content-md-center">
            <div class="col-md-4">
              <div class="Box">
                <div class="Quote">
                  <img src="img/icon/quote.svg" alt="Quote" />
                  <div class="Rating">
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                  </div>
                </div>
                <p class="Pra_1">
                  Rent a car at our rental with an easy and fast process without
                  disturbing your productivity
                </p>
                <div class="Profile">
                  <img src="img/profile_1.png" alt="Profile" />
                  <div class="Name">
                    <h4 class="Head_6">Melinda Lenny</h4>
                    <p class="Pra_1">Medan</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="Box">
                <div class="Quote">
                  <img src="img/icon/quote.svg" alt="Quote" />
                  <div class="Rating">
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                  </div>
                </div>
                <p class="Pra_1">
                  Rent a car at our rental with an easy and fast process without
                  disturbing your productivity
                </p>
                <div class="Profile">
                  <img src="img/profile_2.png" alt="Profile" />
                  <div class="Name">
                    <h4 class="Head_6">Jacob Stevan</h4>
                    <p class="Pra_1">Medan</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="Box">
                <div class="Quote">
                  <img src="img/icon/quote.svg" alt="Quote" />
                  <div class="Rating">
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                    <img src="img/icon/star.svg" alt="Star" />
                  </div>
                </div>
                <p class="Pra_1">
                  Rent a car at our rental with an easy and fast process without
                  disturbing your productivity
                </p>
                <div class="Profile">
                  <img src="img/profile_3.png" alt="Profile" />
                  <div class="Name">
                    <h4 class="Head_6">Roben Musstar</h4>
                    <p class="Pra_1">Bali</p>
                  </div>
                </div>
              </div>
            </div>
            <a href="#" class="Button BtnFill">
              See All
            </a>
          </div>
        </div>
      </section>

      <footer class="Footer">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <img src="img/logo.png" alt="Logo" />
              <p class="Pra_1">
                Find the perfect vehicle for your next adventure with TAK 8.
                Experience the freedom and convenience of exploring Perth with a
                rental car from TAK 8. Enjoy the best car rental Perth service
                and make your journey unforgettable!
              </p>
              <div class="SocialIcon">
                <a href="#">
                  <svg width="16" height="16" viewBox="0 0 16 16">
                    <path d="M12.4653 15.8844C12.4653 14.0733 12.4727 12.2937 12.4593 10.5143C12.4565 10.1462 12.4158 9.76883 12.3251 9.41285C11.9376 7.89147 10.2412 7.98844 9.55128 8.67998C9.12394 9.10826 8.92953 9.60884 8.93361 10.2155C8.94524 11.9722 8.93581 13.729 8.93471 15.4856C8.93471 15.6114 8.93471 15.7371 8.93471 15.8874H5.56567V5.80004H8.91161V7.1773C9.11498 6.93746 9.26807 6.75923 9.41879 6.57881C10.7775 4.95228 14.6985 4.99707 15.5835 8.21335C15.7479 8.81866 15.8364 9.44204 15.8471 10.0692C15.8807 11.9421 15.8594 13.8159 15.8586 15.6895C15.8586 15.7458 15.8485 15.8022 15.8406 15.8847L12.4653 15.8844Z" />
                    <path d="M0.333984 15.8827V5.79395H3.67159V15.8827H0.333984Z" />
                    <path d="M0.134034 2.64348C0.134977 1.59926 0.922542 0.877072 2.05006 0.886816C3.13452 0.896246 3.92648 1.64939 3.92428 2.66957C3.92208 3.66318 3.11 4.39841 2.0037 4.40862C0.917985 4.41852 0.133248 3.67748 0.134034 2.64348Z" />
                  </svg>
                </a>
                <a href="#">
                  <svg width="20" height="16" viewBox="0 0 20 16">
                    <path d="M6.32763 12.6403C4.60381 12.4846 3.45275 11.6135 2.80766 9.99977C3.38429 10.0681 3.91877 10.0826 4.47184 9.92928C2.2163 9.3543 1.4155 7.33784 1.51709 6.18053C2.02691 6.4382 2.55569 6.60919 3.18607 6.62446C2.23784 5.93446 1.69525 5.05046 1.55317 3.94284C1.44771 3.11572 1.61317 2.33074 2.04017 1.58699C4.08681 3.99567 6.67125 5.3258 9.80711 5.53599C9.79367 5.15537 9.76146 4.79647 9.77177 4.43886C9.81594 2.89523 11.009 1.41141 12.5069 1.02969C13.9108 0.671526 15.1431 0.988032 16.204 1.97921C16.2568 2.02835 16.3637 2.0578 16.4346 2.04326C17.1651 1.89422 17.8679 1.63237 18.5179 1.26711C18.5644 1.24116 18.6128 1.21871 18.6953 1.17693C18.408 2.06037 17.87 2.7086 17.1412 3.21879C17.8475 3.15124 18.5134 2.95099 19.1622 2.68412L19.2025 2.72885C18.8983 3.08517 18.6093 3.45603 18.285 3.79303C18.0274 4.06008 17.7235 4.28113 17.4496 4.53291C17.3889 4.58813 17.3342 4.68788 17.334 4.76721C17.3298 8.66743 15.7939 11.8009 12.6194 14.0851C11.2802 15.0496 9.75042 15.5706 8.11476 15.7858C6.56873 15.9882 5.04571 15.8911 3.54662 15.46C2.60545 15.1885 1.70709 14.7861 0.877885 14.2646C0.849906 14.2434 0.82306 14.2207 0.797455 14.1967C1.80679 14.2902 2.76864 14.2228 3.71337 13.9574C4.66199 13.6922 5.54991 13.2448 6.32763 12.6403V12.6403Z" />
                  </svg>
                </a>
                <a href="#">
                  <svg width="8" height="16" viewBox="0 0 8 16">
                    <path d="M7.28803 5.84874L7.02733 8.41701C6.53669 8.41701 6.07574 8.41701 5.61495 8.41626C5.56275 8.41626 5.51085 8.40261 5.4585 8.39901C5.10105 8.37456 5.07075 8.40786 5.0712 8.76321C5.0741 11.0732 5.07565 13.3835 5.07585 15.694C5.07585 15.754 5.06895 15.8127 5.0643 15.8871H2.15223C2.14623 15.7875 2.13723 15.7035 2.13723 15.6195C2.13643 13.4755 2.13673 11.3315 2.13813 9.1874C2.13813 9.0224 2.16018 8.85605 2.16813 8.69031C2.17743 8.48856 2.10183 8.38926 1.87608 8.39886C1.49314 8.41506 1.10899 8.40336 0.711945 8.40336V5.87229C1.10314 5.87229 1.46059 5.86508 1.81773 5.87483C2.05773 5.88143 2.14893 5.80524 2.14773 5.54349C2.14653 4.8445 2.11998 4.13695 2.21583 3.44846C2.41248 2.03652 3.02177 1.19188 4.58581 1.02553C5.46 0.932085 6.34439 0.932085 7.25983 0.887085V3.48401C6.97813 3.48401 6.72583 3.47426 6.47459 3.48641C6.15284 3.50141 5.82959 3.51776 5.51175 3.56666C5.295 3.59996 5.14905 3.76046 5.1387 3.985C5.1123 4.5523 5.1027 5.12034 5.08935 5.68824C5.0844 5.89913 5.24745 5.86074 5.37075 5.86059C5.90444 5.85984 6.43829 5.85309 6.97198 5.84874C7.06603 5.84799 7.15993 5.84874 7.28803 5.84874Z" />
                  </svg>
                </a>
                <a href="#">
                  <svg width="16" height="16" viewBox="0 0 16 16">
                    <path d="M4.33721 0.887085H11.657C11.7043 0.895801 11.7514 0.907672 11.7992 0.912931C12.2089 0.958012 12.6106 1.04817 12.9816 1.22444C14.5975 1.98901 15.4626 3.26886 15.4854 5.05828C15.514 7.27325 15.4926 9.48898 15.492 11.7043C15.492 12.2818 15.3993 12.8463 15.1535 13.3685C14.3949 14.9803 13.1173 15.8462 11.3345 15.8706C9.11054 15.9016 6.88459 15.8798 4.65954 15.8773C3.79729 15.8762 2.99846 15.6437 2.29549 15.1426C1.10385 14.2928 0.506832 13.1233 0.503526 11.6602C0.497916 9.47411 0.498517 7.28803 0.505329 5.10201C0.50608 4.82055 0.521107 4.53248 0.581366 4.25884C0.955538 2.56079 1.98924 1.47644 3.66385 1.01001C3.88249 0.948846 4.11241 0.927057 4.33721 0.887085ZM7.99764 14.5493C9.08068 14.5493 10.1638 14.5493 11.2469 14.5493C11.5897 14.5493 11.9282 14.5145 12.2537 14.3983C13.4409 13.975 14.1598 12.953 14.1622 11.6649C14.1662 9.47922 14.1695 7.29339 14.1566 5.10772C14.1546 4.78133 14.109 4.44022 14.0053 4.13216C13.6015 2.93331 12.5627 2.21893 11.2674 2.21727C9.08649 2.21457 6.90577 2.21036 4.72506 2.22313C4.39867 2.22509 4.05741 2.26972 3.74921 2.3734C2.56057 2.77117 1.83777 3.79811 1.83296 5.07901C1.82475 7.27937 1.8244 9.47977 1.83191 11.6802C1.83371 12.657 2.22442 13.4469 3.01589 14.0262C3.52545 14.3992 4.10685 14.5511 4.73377 14.5499C5.82173 14.5479 6.90968 14.5477 7.99764 14.5493Z" />
                    <path d="M7.99963 12.2466C5.87932 12.2502 4.13259 10.5035 4.13364 8.38245C4.13469 6.26139 5.88293 4.51405 8.00173 4.51991C10.1205 4.52577 11.8519 6.25523 11.8601 8.37689C11.8682 10.4948 10.124 12.243 7.99963 12.2466ZM10.5272 8.38035C10.523 6.98284 9.38647 5.8486 7.99377 5.85281C6.59626 5.85701 5.46217 6.99335 5.46638 8.38621C5.47044 9.78372 6.60663 10.9178 7.99963 10.9137C9.39729 10.9097 10.5314 9.77305 10.5272 8.38035Z" />
                    <path d="M12.02 3.39783C12.5569 3.39633 13.0036 3.84246 12.9967 4.37455C12.9898 4.90664 12.5529 5.34045 12.0233 5.3415C11.4875 5.34255 11.04 4.89507 11.0456 4.36403C11.0488 4.10737 11.1528 3.86226 11.3351 3.68153C11.5173 3.5008 11.7633 3.39891 12.02 3.39783V3.39783Z" />
                  </svg>
                </a>
              </div>
            </div>

            <div class="col-md-2">
              <h2 class="Head_6">Company</h2>
              <ul>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
                <li>
                  <a href="#">Cars</a>
                </li>
                <li>
                  <a href="#">Our Partner</a>
                </li>
              </ul>
            </div>

            <div class="col-md-2">
              <h2 class="Head_6">Services</h2>
              <ul>
                <li>
                  <a href="#">Perth Airport</a>
                </li>
                <li>
                  <a href="#">Vehicle Rental</a>
                </li>
                <li>
                  <a href="#">Car Rental With Seats</a>
                </li>
              </ul>
            </div>

            <div class="col-md-2">
              <h2 class="Head_6">Support</h2>
              <ul>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
                <li>
                  <a href="#">Call Center</a>
                </li>
                <li>
                  <a href="#">Partner With Us</a>
                </li>
                <li>
                  <a href="#">Terms & Condition</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Home;
