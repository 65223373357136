import { create } from "zustand";
import axiosInstance from "../axios-interceptor/axios";
import moment from "moment";

const mainStore = create((set) => ({
  allCars: [],
  selectedRoute: "",
  visitedRoutes: [],
  userBooking: {
    car: { rates: [] },
    rate: {},
    booking: {
      pickUpLocation: "East Perth",
      pickUpDate: moment().format("YYYY-MM-DD"),
      pickUpTime: moment().format("HH:mm"),
      returnLocation: "",
      returnDate: moment().add(7, "days").format("YYYY-MM-DD"),
      returnTime: moment().format("HH:mm"),
      driverAge: "",
      agreeToTerms: true,
    },
    customer: {
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      licenseNumber: "",
      address: "",
      comments: "",
    },
    extras: [],
  },
  getAllCars: async () => {
    try {
      const res = await axiosInstance.get("/cars");
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  getCarById: async (id) => {
    try {
      const res = await axiosInstance.get(`/cars/${id}`);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  getAllRates: async () => {
    try {
      const res = await axiosInstance.get("/rates");
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  getAllExtras: async () => {
    try {
      const res = await axiosInstance.get("/extras");
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  setSelectedRoute: (route) => {
    set({ selectedRoute: route });
  },

  setUserBooking: (booking) => {
    set({ userBooking: booking });
  },

  setVisitedRoutes: (routes) => {
    set({ visitedRoutes: routes });
  },
}));

export default mainStore;
