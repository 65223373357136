import { useEffect, useState } from "react";
import Header from "../components/header";
import mainStore from "../../store/store";
import { useNavigate } from "react-router-dom";
import Summary from "../components/summary";
import sendBookingEmail from "./email";
import Footer from "../components/footer";
import Stepper from "../components/stepper";

const Extras = () => {
  const {
    getAllExtras,
    userBooking,
    setUserBooking,
    visitedRoutes,
    setVisitedRoutes,
  } = mainStore();
  const [allExtras, setAllExtras] = useState([]);
  const navigate = useNavigate();

  const [selectedExtras, setSelectedExtras] = useState(userBooking.extras);

  useEffect(() => {
    initializeExtras();
  }, []);

  useEffect(() => {
    console.log("selectedExtras", selectedExtras);
    let booking = { ...userBooking };
    booking.extras = selectedExtras;
    setUserBooking(booking);
  }, [selectedExtras]);

  const initializeExtras = async () => {
    const updatedRoutes = [...visitedRoutes];
    updatedRoutes.push("extras");
    setVisitedRoutes(updatedRoutes);

    const extras = await getAllExtras();
    if (extras) {
      setAllExtras(extras);
    }
  };

  const onSelectingExtra = (e, extra) => {
    const { checked } = e.target;

    const index = selectedExtras.findIndex((ext) => ext._id === extra._id);
    const updatedExtras = [...selectedExtras];

    if (checked && index === -1) {
      updatedExtras.push(extra);
    } else if (!checked && index > -1) {
      updatedExtras.splice(index, 1);
    }
    setSelectedExtras(updatedExtras);
  };

  const onSubmititngExtras = () => {
    sendBookingEmail(userBooking);
    navigate("/thank-you");
  };

  return (
    <>
      <Header />
      <section class="MainSection">
        <div class="container">
          <h1 class="Head_2">Extras</h1>
          <p class="Head_6"></p>
        </div>
      </section>
      <section class="BookingSection">
        <div class="container">
          <Stepper />
          <div class="row">
            <div class="col-md-8">
              <form class="BookingFrom" action="thankyou.html">
                <div class="CeckBoxMain">
                  {allExtras.map((extra) => (
                    <div class="CheckBox ">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        onClick={(e) => onSelectingExtra(e, extra)}
                        id={extra._id}
                      />
                      <label
                        class="form-check-label cursor-pointer"
                        for={extra._id}
                      >
                        {extra.title} - ${extra.price}
                      </label>
                    </div>
                  ))}
                </div>
                <button class="BtnFill" onClick={() => onSubmititngExtras()}>
                  Submit
                </button>
              </form>
            </div>
            <div class="col-md-4">
              <Summary />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Extras;
